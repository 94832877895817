import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import { HTMLContent } from "../components/Content";

import { getSectionColor } from "../utils/color";

import "../styles/photos.css";

import BackgroundImage from "gatsby-background-image";
import Img from "gatsby-image";

const makeParallaxImg = (splitSrc) => {
  let src = splitSrc.split('src="')[1];

  if (src.includes("title")) {
    let titleSplit = src.split('" title="');
    src = titleSplit[0];
    let title = titleSplit[1];
    return `<div class="parallax" style="background-image: url('${src}')" /> <div class="w-full text-center photo-cap-bg"><p style="color:white;">${title}</p></div>`;
  }

  // return `<div class="parallax" style="background-image: url('${src}')" />`;

  return `<div class="parallax" style="background-image: url('${src}')" />`;
  // return `<img src=${src} />`;
};

const cleanContent = (content, secondary) => {
  const colorChange = content.replace(
    /<h2>/g,
    `<h2 class='text-2xl font-bold text-center pt-0' style='color:${secondary}'>`
  );

  const splitByImg = colorChange.split("<img");

  const finalSections = [];

  for (const section of splitByImg) {
    // prettier-ignore
    if (section.includes("src=")) {
      // Img section
      const [imgSrc, rest] = section.split('">');

      const imgSection = makeParallaxImg(imgSrc);
      finalSections.push({ image: true, content: imgSection });
      if (rest.length > 5) {
        finalSections.push({ image: false, content: rest });
      }
    } else if (section.length > 5) {
      finalSections.push({ image: false, content: section });
    }
  }
  for (const [index, elm] of finalSections.entries()) {
    if (elm.content.includes("<p>")) {
      finalSections[index] = {
        ...elm,
        content: elm.content.replace("<p>", "<p class='firstPara'>"),
      };
    }
  }
  return finalSections;
};

export const PhotoTemplate = ({
  content,
  description,
  author,
  featuredImage,
  title,
  section,
  helmet,
  relatedArticles,
}) => {
  const colors = getSectionColor(section);

  const { primary, secondary } = colors;

  const mainRef = useRef(null);

  const contentSections = cleanContent(content, secondary);

  useEffect(() => {
    if (mainRef && mainRef.current) {
      mainRef.current.style.setProperty("--secondary-color", secondary);
      mainRef.current.style.setProperty("--primary-color", primary);
    }
  }, [mainRef]);

  return (
    <section
      className='article-template'
      style={{
        objectFit: "cover",
      }}
      ref={mainRef}
    >
      {helmet || ""}

      <BackgroundImage
        Tag='div'
        className='h-screen w-full z-20'
        fluid={featuredImage.childImageSharp.fluid}
      >
        <div class='max-w-7xl h-full mx-auto px-4 sm:px-6 lg:px-8 flex flex-col justify-center items-center'>
          <div class='max-w-3xl mx-auto bg-gray-900 w-full bg-opacity-25'>
            <div class='flex flex-col justify-center items-center'>
              <p class='text-4xl text-white font-bold text-center'>{title}</p>
              <p class='text-2xl text-white font-bold text-center'>
                {description}
              </p>
              <p class='text-xl text-white font-bold text-center'>{author}</p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className='flex flex-col justify-center items-center'>
        {contentSections.map((contentObject) => {
          if (contentObject.image) {
            return (
              <div
                className='w-full flex flex-col justify-center items-center image-container'
                dangerouslySetInnerHTML={{
                  __html: contentObject.content,
                }}
              ></div>
            );
          } else {
            return (
              <div className='mainContainer'>
                <div class='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
                  <div class='max-w-3xl  mx-auto'>
                    <div className='relative py-16 text-white overflow-hidden'>
                      <div className='relative px-4 sm:px-6 lg:px-8'>
                        <div
                          className='mt-3 prose prose-indigo prose-lg text-gray-900 mx-auto text-justify'
                          dangerouslySetInnerHTML={{
                            __html: contentObject.content,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        })}
        <div className='related-articles w-full flex flex-col justify-center'>
          <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
            <div class='max-w-3xl  mx-auto text-white'>
              <p className='text-3xl font-bold text-center'>Related Articles</p>
            </div>
          </div>
          <div className='flex flex-col lg:flex-row justify-around w-full items-start'>
            {relatedArticles.map((article) => {
              return (
                <Link to={article.fields.slug}>
                  <div class='py-6'>
                    <div class='flex flex-col max-w-md bg-white shadow-lg rounded-lg overflow-hidden h-full'>
                      <div>
                        <Img
                          className='object-cover'
                          fluid={
                            article.frontmatter.featuredimage?.childImageSharp
                              .fluid
                          }
                        ></Img>
                      </div>
                      <div class='p-4'>
                        <h1 class='text-gray-900 font-bold text-xl'>
                          {article.frontmatter.title}
                        </h1>
                        <p class='mt-2 text-gray-600 text-sm'>
                          {article.frontmatter.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
      {/* <PostContent content={content} /> */}
    </section>
  );
};

PhotoTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const PhotoPost = ({ data }) => {
  const { markdownRemark: post, allMarkdownRemark: section } = data;

  const relatedArticles = section.nodes.filter(
    (element) => element.id !== post.id
  );

  return (
    <Layout>
      <PhotoTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate='%s | Blog'>
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name='description'
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        author={post.frontmatter.author}
        featuredImage={post.frontmatter.featuredimage}
        title={post.frontmatter.title}
        section={post.frontmatter.section}
        relatedArticles={relatedArticles}
      />
    </Layout>
  );
};

PhotoPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default PhotoPost;

export const pageQuery = graphql`
  query PhotoPostByID($id: String!, $section: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        description
        author
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        section
      }
    }
    allMarkdownRemark(filter: { frontmatter: { section: { eq: $section } } }) {
      totalCount
      nodes {
        fields {
          slug
        }
        id
        frontmatter {
          title
          featuredimage {
            childImageSharp {
              fluid(maxWidth: 2048, maxHeight: 1024, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          description
        }
      }
    }
  }
`;
